<template>
  <page-main title="Saved Cover Letters" :displayBeta="true" noBottom>
    <template v-slot:aside>
      <p>
        Your latest letter and all previously saved letters can be found here.
        From this page you can:
      </p>
      <li>View, download, or delete your saved cover letters.</li>
      <li>Update your cover letter titles and notes.</li>
      <li>Go back and make edits to your saved cover letters.</li>
      <li>Start a new cover letter from scratch.</li>
      <li>
        Start a new cover letter using the same resume, target industry, and
        target company as your latest letter.
      </li>
      <v-btn outlined class="mt-4" block @click="navToContactUs">
        Any feedback? Contact us!
      </v-btn>
    </template>
    <div
      class="py-2 px-4 elevation-2 page-error-bar"
      v-if="hasError || hasLocalError"
    >
      <v-icon color="red">mdi-alert-outline</v-icon>
      <span class="red--text pl-3">{{ this.errorMessage }}</span>
    </div>
    <v-container>
      <v-overlay color="primary" :absolute="true" v-if="loading || saveLoading">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-expansion-panels v-model="panel1" multiple>
            <r-expansion-panel v-if="latestCoverLetter">
              <r-expansion-panel-header>
                <div class="d-flex align-center justify-space-between">
                  <div class="text-body-1">Latest Cover Letter</div>
                  <div class="text-body-2 pr-2">
                    {{ getLength([latestCoverLetter]) }}
                  </div>
                </div>
              </r-expansion-panel-header>
              <r-expansion-panel-content>
                <v-container>
                  <v-row class="pt-4 pb-2">
                    <v-col cols="12" class="r-col">
                      <cover-letter-submission
                        :id="latestCoverLetter.id"
                        :name="latestCoverLetter.submissionMetaData.name"
                        :note="latestCoverLetter.submissionMetaData.note"
                        :subLines="buildSublines(latestCoverLetter)"
                        :imgHref="latestCoverLetter.previewRef"
                        :imgRef="latestCoverLetter.imageRef"
                        :fileHref="latestCoverLetter.fileRef"
                        :pdfHref="latestCoverLetter.pdfRef"
                        :fileName="latestCoverLetter.fileName"
                        @removeCoverLetter="triggerRemove($event)"
                        @editMetaData="handleEditMetaData($event)"
                        @editCoverLetter="handleEditCoverLetter($event)"
                      >
                      </cover-letter-submission>
                    </v-col>
                  </v-row>
                </v-container>
              </r-expansion-panel-content>
            </r-expansion-panel>
          </r-expansion-panels>
          <r-expansion-panels v-model="panel2" multiple>
            <r-expansion-panel v-if="savedCoverLetters.length > 0">
              <r-expansion-panel-header>
                <div class="d-flex align-center justify-space-between">
                  <div class="text-body-1">Saved Cover Letters</div>
                  <div class="text-body-2 pr-2">
                    {{ getLength(savedCoverLetters) }}
                  </div>
                </div>
              </r-expansion-panel-header>
              <r-expansion-panel-content>
                <v-container>
                  <v-row
                    class="pt-4 pb-2"
                    v-for="coverLetter in savedCoverLetters"
                    :key="coverLetter.id"
                  >
                    <v-col cols="12" class="r-col">
                      <cover-letter-submission
                        :id="coverLetter.id"
                        :name="coverLetter.submissionMetaData.name"
                        :note="coverLetter.submissionMetaData.note"
                        :subLines="buildSublines(coverLetter)"
                        :imgHref="coverLetter.previewRef"
                        :imgRef="coverLetter.imageRef"
                        :fileHref="coverLetter.fileRef"
                        :pdfHref="coverLetter.pdfRef"
                        :fileName="coverLetter.fileName"
                        @editCoverLetter="handleEditCoverLetter($event)"
                        @removeCoverLetter="triggerRemove($event)"
                        @editMetaData="handleEditMetaData($event)"
                      >
                      </cover-letter-submission>
                    </v-col>
                  </v-row>
                </v-container>
              </r-expansion-panel-content>
            </r-expansion-panel>
          </r-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <div class="py-2 px-4 elevation-2 page-error-bar" v-if="isError">
          <v-icon color="red">mdi-alert-outline</v-icon>
          <span class="red--text pl-3 mb-12">{{ this.errorMessage2 }}</span>
        </div>
      </v-row>
      <v-row>
        <v-col cols="6" class="r-col" align="left">
          <v-btn
            outlined
            color="primary"
            class="mb-12"
            @click="createNewCoverLetter()"
            >Create New Cover Letter
          </v-btn>
        </v-col>
        <v-col cols="6" class="r-col" align="right">
          <v-btn
            outlined
            color="primary"
            v-if="coverLetterList.length > 0"
            class="mb-12"
            @click="createFromLatest()"
            >Create From Latest Cover Letter
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="premiumBanner" persistent>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <h4 class="pt-2 text-center">
          Oops! Looks like you will need Resumation Plus to use Cover Letter
          Creator feature!
        </h4>
        <p class="mt-2">
          Upgrade to get unlimited use of the Optimize with AI features,
          hide/show parts of your resume in-tool (customizations), save and
          store multiple resumes, and much more for only $5 a month!
        </p>
        <h4 class="pt-2 text-center">
          Would you like to upgrade to Resumation Plus?
        </h4>
      </v-card-text>
      <v-card-actions
        :class="
          $vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'
        "
      >
        <v-btn color="gray" outlined text @click="noSubscription()">
          Not Now
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined text @click="navToPremiumPage()">
          Yes - let's do it!
        </v-btn>
      </v-card-actions>
    </r-dialog>
    <r-dialog
      v-model="removeDialog"
      title="Confirm Removal"
      persistent
      transition="dialog-top-transition"
    >
      <v-overlay :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary mb-0">
          Are you sure you want to remove this cover letter?
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined @click="removeDialog = false"
          >Cancel</v-btn
        >
        <v-btn color="primary" outlined @click="removeCoverLetter"
          >Remove</v-btn
        >
      </v-card-actions>
    </r-dialog>
    <r-dialog
      v-model="editMetaDataDialog"
      title="Confirm Edit"
      persistent
      transition="dialog-top-transition"
    >
      <v-overlay :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <h4 class="pt-2 text-center">
          Fill out info to continue to creating a cover letter.
        </h4>
        <v-col cols="12">
          <v-textarea
            label="Cover Letter Title"
            v-model="coverLetterTitle"
            :rules="[rules.required]"
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Cover Letter Notes"
            v-model="coverLetterNote"
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Date Created"
            :value="formattedDate"
            readonly
            rows="2"
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions
        :class="
          $vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'
        "
      >
        <v-btn color="gray" outlined @click="editMetaDataDialog = false">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          outlined
          text
          :disabled="!coverLetterTitle || loading"
          @click="saveEdit()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { required } from "../../utility/validation.js";
import * as disp from "../../utility/display";
import * as dutil from "../../utility/dateutil";
import CoverLetterSubmission from "../../components/CoverLetterSubmissionLine.vue";

// @ is an alias to /src
export default {
  name: "SavedCoverLetters",
  data: () => ({
    panel1: [0],
    panel2: [0],
    localCoverLetters: [
      {
        title: "Saved Cover Letters",
        reviews: [],
      },
    ],
    loading: false,
    saveLoading: false,
    premiumBanner: false,
    errorMessage: "",
    errorMessage2: "",
    hasLocalError: false,
    removeId: 0,
    editId: 0,
    removeDialog: false,
    editMetaDataDialog: false,
    coverLetterTitle: "",
    coverLetterNote: "",

    rules: {
      required: (value) => required(value),
    },
  }),
  components: {
    "cover-letter-submission": CoverLetterSubmission,
  },
  methods: {
    noSubscription() {
      this.$router.back();
    },
    async loadData() {
      await this.refreshPremium();
      this.loading = true;
      if (this.isSaving) {
        this.saveLoading = true;
      } else {
        this.saveLoading = false;
      }

      try {
        this.loading = true;
        if (!this.isPremium) {
          this.dialog = false;
          this.premiumBanner = true;
        } else {
          await this.loadCoverLetterList();
          this.loading = false;
          if (this.hasError) {
            this.errorMessage = "Error retrieving cover letter list";
          } else {
            this.isError = false;
            this.hasLocalError = false;
          }
        }
      } catch (e) {
        this.hasError = true;
        this.errorMessage = "Error retrieving cover letter list";
        this.loading = false;
      }
    },
    getLength(coverLetters) {
      if (coverLetters != null) {
        return `(${coverLetters.length})`;
      }
      return "(-)";
    },
    buildSublines(coverLetter) {
      let lines = [];
      if (
        !disp.IsNullorWhitespace(coverLetter.submissionMetaData.dateSubmitted)
      ) {
        lines.push(
          `Saved: ${dutil.dateStringToFormat(
            coverLetter.submissionMetaData.dateSubmitted
          )}`
        );
      }
      if (
        !disp.IsNullorWhitespace(coverLetter.submissionMetaData.dateUpdated)
      ) {
        lines.push(
          `Updated: ${dutil.dateStringToFormat(
            coverLetter.submissionMetaData.dateUpdated
          )}`
        );
      }
      if (!disp.IsNullorWhitespace(coverLetter.submissionMetaData.note)) {
        lines.push(`Note: ${coverLetter.submissionMetaData.note}`);
      }
      return lines;
    },
    async createNewCoverLetter() {
      try {
        if (!this.hasError) {
          await this.newCoverLetter();
          this.nav("/CoverLetterTargetIndustry");
        } else {
          this.isError = true;
          this.errorMessage2 =
            "Can not create new cover letter, error loading cover letter list";
        }
      } catch (e) {
        this.isError = true;
        this.errorMessage2 = "Error retrieving creating new cover letter";
      }
    },
    async createFromLatest() {
      try {
        if (!this.hasError) {
          const latestCoverLetterId = this.getLatestCoverLetterId();
          if (latestCoverLetterId) {
            await this.newCoverLetter();
            await this.loadCoverLetter({ reviewId: latestCoverLetterId });
            this.coverLetterId = null;
            if (this.isError) {
              this.errorMessage = "Error retrieving latest cover letter ";
            } else {
              this.nav("/CoverLetterTargetIndustry");
            }
          }
          this.errorMessage = "Can not get latest cover letter";
        } else {
          this.isError = true;
          this.errorMessage2 =
            "Can not create from latest cover letter, error loading cover letter list";
        }
      } catch (e) {
        this.isError = true;
        this.errorMessage = "Error retrieving latest cover letter";
      }
    },
    getLatestCoverLetterId() {
      if (this.coverLetterList.length) {
        const latestCoverLetter = this.coverLetterList.reduce(
          (latest, coverLetter) => {
            return new Date(latest.submissionMetaData.dateUpdated) >
              new Date(coverLetter.submissionMetaData.dateUpdated)
              ? latest
              : coverLetter;
          }
        );
        return latestCoverLetter.id;
      }
      return null;
    },
    async handleEditCoverLetter(id) {
      try {
        await this.loadCoverLetter({ reviewId: id });
        if (this.isError) {
          this.hasError = true;
          this.errorMessage = "Error loading selected cover letter";
        } else {
          this.nav("/CoverLetterTargetIndustry");
        }
      } catch (e) {
        this.hasError = true;
        this.errorMessage = "Error loading selected cover letter";
      }
    },
    handleEditMetaData(id) {
      const coverLetter = this.coverLetterList.find((cl) => cl.id === id);
      if (!(coverLetter == undefined || coverLetter == null)) {
        this.coverLetterTitle = coverLetter.submissionMetaData.name;
        this.coverLetterNote = coverLetter.submissionMetaData.note;
        this.editMetaDataDialog = true;
        this.editId = id;
      } else {
        this.hasLocalError = true;
        this.errorMessage = "Error editing cover letter title and note.";
      }
    },
    async saveEdit() {
      try {
        this.name = this.coverLetterTitle;
        this.note = this.coverLetterNote;
        this.coverLetterId = this.editId;
        this.loading = true;
        await this.updateCoverLetter();
        await this.loadData();
        this.loading = false;
        this.editMetaDataDialog = false;
      } catch (e) {
        this.hasError = true;
        this.errorMessage = "Error saving cover letter title and note";
      }
    },
    triggerRemove(id) {
      this.removeDialog = true;
      this.removeId = id;
    },
    async removeCoverLetter() {
      try {
        this.loading = true;
        await this.deleteCoverLetter({ reviewId: this.removeId });
        await this.loadData();
        if (this.isError) {
          this.errorMessage = "Error deleting cover letter";
        } else {
          this.removeDialog = false;
          this.loading = false;
        }
      } catch (e) {
        this.errorMessage = "Error deleting cover letter";
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    navToPremiumPage() {
      this.dialog = false;
      this.$router.push({ path: "/Upgrade" });
    },
    navToContactUs() {
      window.open("https://www.resumation.net/contact", "_blank");
    },
    ...mapActions("activeCoverLetter", ["newCoverLetter"]),
    ...mapActions("activeCoverLetter", ["loadCoverLetter"]),
    ...mapActions("activeCoverLetter", ["deleteCoverLetter"]),
    ...mapActions("activeCoverLetter", ["updateCoverLetter"]),
    ...mapActions("coverLetter", ["loadCoverLetterList"]),
    ...mapActions("auth", ["refreshPremium"]),
  },
  computed: {
    ...mapFields("activeCoverLetter", [
      "coverLetterId",
      "name",
      "note",
      "isError",
      "isPageAllowed",
      "isSaving",
    ]),
    ...mapFields("coverLetter", ["coverLetterList", "hasError"]),
    ...mapFields("userSubmissions", {
      resumes: "resumes",
    }),
    ...mapFields("auth", {
      isPremium: "isPremium",
    }),
    formattedDate() {
      const date = new Date();
      return date.toLocaleDateString();
    },
    latestCoverLetter() {
      if (this.coverLetterList.length) {
        return this.coverLetterList.reduce((latest, coverLetter) => {
          return new Date(latest.submissionMetaData.dateUpdated) >
            new Date(coverLetter.submissionMetaData.dateUpdated)
            ? latest
            : coverLetter;
        });
      }
      return null;
    },
    savedCoverLetters() {
      if (this.coverLetterList.length) {
        return this.coverLetterList
          .filter((coverLetter) => coverLetter !== this.latestCoverLetter)
          .sort(
            (a, b) =>
              new Date(b.submissionMetaData.dateUpdated) -
              new Date(a.submissionMetaData.dateUpdated)
          );
      }
      return [];
    },
  },
  watch: {
    isSaving(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.loadData();
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
  beforeRouteLeave(to, from, next) {
    if (to.fullPath == "/CoverLetterTargetIndustry") {
      this.isPageAllowed = true;
    } else {
      this.isPageAllowed = false;
    }
    next();
  },
};
</script>
