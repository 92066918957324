<template>
  <div class="d-flex justify-space-between align-start">
    <div class="wide">
      <p class="text-body-1 mb-1">{{ name }}</p>
      <p v-for="(line, i) in subLines" :key="i" class="text-body-2 text--secondary mb-0 note">
        {{ line }}
      </p>
      <u>
        <a
        @click="handleEdit()"
        >
        Edit 
        </a>
      </u>
    </div>
    <div class="d-flex pl-2">
      <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-end align-start">
        <div class="pr-3">
          <img 
            aspect-ratio="1" 
            width="130" 
            max-width="130" 
            :src="imgHref" 
            contain 
            class="pa-1 resume-img" />
        </div>
      </div>
      <div class="button-holder">
        <v-btn outlined block @click="handleView()"> View Cover Letter </v-btn>
        <v-btn outlined block class="mt-2" @click="handleEditCoverLetter()"> Edit Cover Letter </v-btn>
        <v-btn block outlined class="mt-2" @click="handleDownloadDocx()"> Download Word (.docx) </v-btn>
        <v-btn block outlined class="mt-2" @click="handleDownloadPDF()"> Download PDF</v-btn>
        <v-btn block outlined class="mt-2" @click="handleDelete()"> Delete File </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";




// @ is an alias to /src
export default {
  name: "CoverLetterSubmissionLine",
  props: {
    id: String,
    name: String,
    note: {
      type: String,
      default: "Select a Cover Letter Format",
    },
    subLines: {
      type: Array,
      default: () => [],
    },
    imgHref: String,
    fileHref: String,
    pdfHref: String,
    fileName: String,
    imgRef: String
  },
  methods: {
    async handleEditCoverLetter() {
      this.$emit("editCoverLetter", this.id);
    },
    async handleEdit() {
      this.$emit("editMetaData", this.id);
    },
    handleView() {
      this.$router.push({ path: `/CoverLetterView/${this.id}` });
    },
    async handleDelete() {
      this.$emit("removeCoverLetter", this.id);
    },
    async handleDownloadDocx() {
      this.downloadFileFromUrl(this.fileHref);
    },
    async handleDownloadPDF() {
      this.downloadFileFromUrl(this.pdfHref);
    },
    downloadFileFromUrl(fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("activeCoverLetter", ["loadCoverLetter"]),
  },
};
</script>

<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}
.note {
  word-wrap: break-word;
  max-width: 300px;
}
</style>
