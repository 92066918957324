import { render, staticRenderFns } from "./CoverLetterSubmissionLine.vue?vue&type=template&id=bbf0d234&scoped=true&"
import script from "./CoverLetterSubmissionLine.vue?vue&type=script&lang=js&"
export * from "./CoverLetterSubmissionLine.vue?vue&type=script&lang=js&"
import style0 from "./CoverLetterSubmissionLine.vue?vue&type=style&index=0&id=bbf0d234&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbf0d234",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
